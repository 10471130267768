<template>
    <div>
        <el-dialog
            title="修改文本资料"
            :visible.sync="showEditTextContentDialog"
            width="60%"
        >
            <tinymce v-model="content" :height="300" />
            <span slot="footer" class="dialog-footer">
                <el-button @click="showEditTextContentDialog = false"
                    >取 消</el-button
                >
                <el-button type="primary" @click="submitEditTextContent"
                    >确 定</el-button
                >
            </span>
        </el-dialog>

        <el-dialog
            title="上传附件"
            :visible.sync="showUpdateFileDialog"
            width="60%"
        >
            <el-upload
                ref="upload_attach"
                class="upload-demo sendData"
                action=""
                :on-preview="handlePreview"
                :on-change="fileChange"
                :limit="1"
                :http-request="upload"
                :auto-upload="false"
                :file-list="fileList"
            >
                <el-button
                    size="small"
                    type="primary"
                    class="dataSendBtn"
                    slot="trigger"
                    style="margin: 10px"
                    >上传数据</el-button
                >
                <!-- <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div> -->
            </el-upload>

            <span slot="footer" class="dialog-footer">
                <el-button @click="showUpdateFileDialog = false"
                    >取 消</el-button
                >
                <el-button type="primary" @click="upload">确 定</el-button>
            </span>
        </el-dialog>

        <infoHeader :content_name="'资料详情'" />

        <el-descriptions title="文档信息" direction="vertical" border>
            <template slot="extra">
                <el-button type="danger" @click="delMaterial"
                    >删除文档</el-button
                >
            </template>
            <el-descriptions-item label="资料标题">{{
                query.material_title
            }}</el-descriptions-item>
            <el-descriptions-item label="创建时间">{{
                query.create_time
            }}</el-descriptions-item>
        </el-descriptions>

        <el-descriptions title="文档文本" style="margin-top: 50px">
            <template slot="extra">
                <el-button type="primary" @click="oepnEditTextContentDialog"
                    >编辑资料文本</el-button
                >
            </template>
        </el-descriptions>
        <div v-html="content"></div>

        <el-descriptions title="文档附件" style="margin-top: 50px">
            <template slot="extra">
                <el-button type="primary" @click="openUpdateFileDialog"
                    >上传附件</el-button
                >
            </template>
        </el-descriptions>

        <el-table :data="accessoryTableData">
            <el-table-column prop="id" label="id" width="140"></el-table-column>
            <el-table-column label="附件名" prop="material_title" width="300">
            </el-table-column>

            <el-table-column label="上传时间" prop="create_time" width="300">
            </el-table-column>
            <el-table-column label="文件链接" prop="file"> </el-table-column>

            <el-table-column label="更多" fixed="right" width="80">
                <template slot-scope="scope">
                    <el-button @click="deleteFile(scope.row)" type="primary"
                        >删除</el-button
                    >
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
import {
    product_info_material_get,
    product_info_material_update,
    product_info_delete,
    product_info_accessory_get,
    product_info_accessory_create,
    product_info_accessory_delete
} from '@/api/manage/operation/product/product.js';
import { upload } from '@/api/common.js';
import tinymce from '@/components/Tinymce/';
export default {
    name: 'start',
    data() {
        return {
            query: {},
            content: '',
            fileList: [],
            accessoryTableData: [],
            showEditTextContentDialog: false,
            showUpdateFileDialog: false
        };
    },
    components: {
        tinymce
    },
    created() {
        this.query = this.$route.query;
        this.initData();
    },
    methods: {
        initData() {
            product_info_material_get({
                product_material_id: this.$route.query.id
            }).then(res => {
                this.content = res.data;
            });

            product_info_accessory_get({
                product_material_id: this.$route.query.id
            }).then(res => {
                this.accessoryTableData = res.data;
            });
        },
        oepnEditTextContentDialog() {
            this.showEditTextContentDialog = true;
        },
        submitEditTextContent() {
            let temp = this.content.replace('<p>', '').replace('</p>', '');
            product_info_material_update({
                product_material_id: this.query.id,
                content: temp
            }).then(res => {
                if (res.code == 200) {
                    this.$message.success('更改成功');
                    this.showEditTextContentDialog = false;
                } else {
                    this.$message.success('更改失败');
                }
            });
        },
        openUpdateFileDialog() {
            this.showUpdateFileDialog = true;
        },
        upload() {
            if (this.uploadLast == null) {
                this.$message.warning('请上传文件');
            } else {
                let temp = {
                    product_material_id: this.$route.query.id, //资料ID
                    file: this.uploadLast.filename, //文件名 统一上传接口返回的 filename字段
                    file_name: this.uploadLast.sourcefilename //源文件名 统一上传接口返回的 sourcefilename字段
                };

                product_info_accessory_create(temp).then(res => {
                    console.log(temp);
                    if (res.code == 200) {
                        this.$message.success('上传成功');
                        this.fileList = [];
                        this.showUpdateFileDialog = false;
                        this.initData();
                    } else {
                        this.$message.info('上传失败');
                    }
                });
            }
        },
        handlePreview(file) {
            console.log(file, '文件列表');
        },
        fileChange(file, fileList) {
            console.log(file, fileList);
            upload;
            console.log(this.$refs.upload_attach);
            var formData = new FormData();
            formData.append('file', file.raw);
            formData.append('uploadKey', 'product_material');

            upload(formData).then(res => {
                console.log('图片预处理的结果', res);
                this.uploadLast = res.data;
            });
        },
        deleteFile(row) {
            this.$confirm('是否确定删除', '确认信息', {
                distinguishCancelAndClose: true,
                confirmButtonText: '删除',
                cancelButtonText: '放弃删除'
            })
                .then(() => {
                    product_info_accessory_delete({
                        accessory_id: row.id
                    }).then(res => {
                        if (res.code == 200) {
                            this.$message.success('成功删除');
                            this.initData();
                        } else {
                            this.$message.info(res.msg);
                        }
                    });
                })
                .catch(action => {
                    this.$message({
                        type: 'info',
                        message: action === 'cancel' ? '放弃删除' : '停留在当前'
                    });
                });
        },
        delMaterial() {
            this.$confirm('是否确定删除', '确认信息', {
                distinguishCancelAndClose: true,
                confirmButtonText: '删除',
                cancelButtonText: '放弃删除'
            })
                .then(() => {
                    product_info_delete({
                        product_material_id: this.$route.query.id
                    }).then(res => {
                        if (res.code == 200) {
                            this.$message.success('成功删除');
                            this.$router.go(-1);
                        } else {
                            this.$message.info(res.msg);
                        }
                    });
                })
                .catch(action => {
                    this.$message({
                        type: 'info',
                        message: action === 'cancel' ? '放弃删除' : '停留在当前'
                    });
                });
        }
    }
};
</script>

<style lang="scss" scoped></style>
